/**
 * 网络请求配置
 */
import axios from 'axios'
import { getuserInfo } from 'api/apis'
import ALLURL from 'envconfig/config' //自定义多环境api请求路径
import { md5 } from 'common/utils/md5'
import { Button, Notification } from '@arco-design/web-react'
import { getUrlToken, isParams } from 'common/utils/index'
import cancelClass from './cancelClass'
axios.defaults.timeout = 100000
axios.defaults.baseURL = ALLURL.baseURL //若使用定向代理将baseURL替换成'/api'即可
const loginUrl = ALLURL.loginURL + '/login.html'
// const loginUrl = 'http://localhost:9983/login.html'
const apiInfo = {
  'Content-Type': 'application/json',
  appId: 'RzMKtFCAH5',
  token: '', // 客户端加密秘钥ATY4tnGA4wVyf2z3w2TN
  autherization: getUrlToken('token')
    ? getUrlToken('token')
    : localStorage.getItem('token')
      ? localStorage.getItem('token')
      : '',
}
// let loading = "";
// let map = [];
// const CancelToken = axios.CancelToken;
// let cancel; // 用于保存取消请求的函数

export function getRequestHeaders (params) {
  const time_stamp = parseInt(new Date().getTime() / 1000)
  let AccessToken,AccessSecret;
  if (isParams(localStorage.getItem('userinfo'))) {
    apiInfo.token = JSON.parse(localStorage.getItem('userinfo')).login_md5_key
      ? JSON.parse(localStorage.getItem('userinfo')).login_md5_key
      : JSON.parse(localStorage.getItem('userinfo')).default_md5_key
        ? JSON.parse(localStorage.getItem('userinfo')).default_md5_key
        : ''
    localStorage.setItem('md5_key', apiInfo.token)

         //内嵌登录的key
    AccessToken = JSON.parse(localStorage.getItem('userinfo'))
    ? JSON.parse(localStorage.getItem('userinfo')).access_key
    : ''
    AccessSecret = JSON.parse(localStorage.getItem('userinfo'))
    ? JSON.parse(localStorage.getItem('userinfo')).access_secret
    : ''
  }

  //console.log(params, "paramsparamsparamsparams");

  if (getUrlToken('token')) {
    localStorage.clear()
    localStorage.setItem('token', getUrlToken('token'))
    if (getUrlToken('btype')) {
      localStorage.setItem('locationKey', getUrlToken('btype'))
      if (getUrlToken('modle')) {
        localStorage.setItem('modleClassName', getUrlToken('modle'))
      }
      if (getUrlToken('appid')) {
        localStorage.setItem('appidOfficial', getUrlToken('appid'))
      }
    }
    if (window.top !== window.self) {
      window.top.location = window.self.location
    } else {
      var url = window.location.search
      if (url) {
        var old_url = window.location.href
        var new_url = old_url.substring(0, old_url.indexOf('?'))
        window.self.location = new_url
      }
    }
  }
  localStorage.setItem('Signature', sign(params, time_stamp))

  return {
    AppId: apiInfo.appId,
    Authorization: apiInfo.autherization,
    Timestamp: time_stamp,
    Signature: sign(params, time_stamp),
    AccessToken,
    AccessSecret,
  }
}
// 接口参数签名规则
function sign (params, time_stamp) {
  // 1.先对key进行自然排序
  let sorted_keys = []
  for (let i in params) {
    i !== 'cancelToken' && sorted_keys.push(i)
  }
  sorted_keys.sort()

  // 2.将排好序的业务参数，时间戳和签名密钥拼接起来
  let joined_str =
    sorted_keys
      .filter((key) => params[key] !== '' && typeof params[key] !== 'undefined') //过滤无效值
      .map((key) => key + '=' + encodeURIComponent(params[key])) //生成 "key=value" 字符串
      .join('&') +
    `&${time_stamp}` +
    `&${apiInfo.token}` //拼接成一串

  joined_str = joined_str.replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase()
  })
  // 3.用MD5进行hash并转换为大写，返回结果
  // console.log(joined_str, "接口参数签名规则");
  return md5(joined_str).toUpperCase()
}

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    config.cancelToken = cancelClass.cancelRequest.source.token
    let processData = true
    let data = config.data
    let params = config.data ? config.data : config.params
    config.data = data

    let contentType = 'application/x-www-form-urlencoded;charset=UTF-8'
    
    if (config.method === 'put' || config.method === 'post') {
      contentType = 'application/json;charset=utf-8'
    }
    if (params instanceof FormData) {
      processData = false
      contentType = false
      contentType = 'multipart/form-data'
      //console.log(params.get("model_capability"), "formData-model_capability");
      if (params.get('model_capability') && config.url === '/v2/file/up') {
        params = {
          model_capability: params.get('model_capability'),
        }
      }
    }
    config.headers = getRequestHeaders(params)
    config.processData = processData
    config.headers['Content-Type'] = contentType
    //针对sass aigc获取应用code的接口 只传Authorization参数
    if(config.url === "/aigc/code"){
      config.headers = {}
      config.headers['Authorization'] = "Bearer " + localStorage.getItem("BearerToen")
    } else if(config.url === "/v3/apps/delete"){
      config.headers['Content-Type'] = 'application/json'
    } else if(config.url === '/aigc/setting'){
      config.headers['token'] = JSON.parse(localStorage.getItem("saastoken"))
    } else if(config.url === '/resumes/cv-recognize'){
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    //console.log(config, "config");
    // if (config.isLoading) {
    //   // 开启loading
    //   //console.log('拼命加载中...')
    // } else {
    //   let cancel;
    //   config.cancelToken = new axios.CancelToken(function (c) {
    //     cancel = c
    //   })
    // }
    // 发送请求前首先检查该请求是否已经重复，重复则进行取消并移除
    // cancelPending(config)
    // // 添加该请求到pendingList中
    // addPending(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    // 请求成功去除pengding状态
    // removePending(response)
    // if (response.status === 200) {
    //   return Promise.resolve(response)
    // }
    if (response.status === 200) {
      if (response.data.code === 2006) {
        //console.log("过期");
        // 关键代码，判断当前页是否有arco-message标签，如果没有则执行弹窗操作
        // 全局拦截多个提示显示一个
        if (document.getElementsByClassName('arco-message').length === 0) {
          // Message.warning(response.data.msg)
        }

        localStorage.removeItem('token')
        localStorage.removeItem('userinfo')
        // window.open(loginUrl)
        window.location = loginUrl
      } else if (response.data.code === 1821) {
        if (document.getElementsByClassName('arco-message').length === 0) {
          Notification.warning({ content: response.data.msg })
        }
      } else if (response.data.code === 3006) {
        updateNotification()
      }

      // return response;
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    //console.log("请求出错：", error);
    // 请求失败去除pengding状态
    // removePending(error.response)
    return Promise.reject(error.response)
  }
)
function updateNotification () {
  const id = `${Date.now()}`
  if (document.querySelectorAll('.arco-notification').length === 0) {
    Notification.info({
      id,
      title: '消息通知',
      content: '您当前的账户权限已变更，请确认更新账户权限!',
      duration: 0,
      btn: (
        <span>
          <Button
            type="secondary"
            size="small"
            onClick={() => Notification.remove(id)}
            style={{ marginRight: 12 }}
          >
            取消
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              Notification.remove(id)
              window.location.reload()
            }}
          >
            更新权限
          </Button>
        </span>
      ),
    })
    getUserInfoLoding()
  }
}
function getUserInfoLoding (val) {
  getuserInfo().then((res) => {
    if (res.code === 200) {
      let userinfodata = res.data
      localStorage.setItem('userinfo', JSON.stringify(userinfodata))
    }
  })
}
/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        landing(url, params, response ? response.data : '')
        resolve(response ? response.data : '')
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post (url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        msag(err)
        reject(err)
      }
    )
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        msag(err)
        reject(err)
      }
    )
  })
}
/**
 * 封装DELETE请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del (url, params = {}) {
  return new Promise((resolve, reject) => {
    if(url === '/v3/apps/delete'){
      axios
      .delete(url, {
        data: params,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        landing(url, params, response ? response.data : '')
        resolve(response ? response.data : '')
      })
      .catch((error) => {
        reject(error)
      })
    } else {
      axios
      .delete(url, {
        params: params,
      })
      .then((response) => {
        landing(url, params, response ? response.data : '')
        resolve(response ? response.data : '')
      })
      .catch((error) => {
        reject(error)
      })
    }
    
  })
}

//统一接口处理，返回数据
export default function (fecth, url, param, cancelToken) {
  if (cancelToken) {
    if (!param) {
      param = {
        cancelToken: cancelToken,
      }
    } else {
      param.cancelToken = cancelToken
    }
  }

  return new Promise((resolve, reject) => {
    switch (fecth) {
      case 'get':
        //console.log("begin a get request,and url:", url);
        get(url, param)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            //console.log("get request GET failed.", error);
            reject(error)
          })
        break
      case 'post':
        post(url, param)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            //console.log("get request POST failed.", error);
            reject(error)
          })
        break
      case 'put':
        put(url, param)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            //console.log("get request PUT failed.", error);
            reject(error)
          })
        break
      case 'delete':
        del(url, param)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            //console.log("get request DELETE failed.", error);
            reject(error)
          })
        break
      default:
        break
    }
  })
}

//失败提示
function msag (err) {
  //console.log(err, "失败提示");
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        Notification.error({ content: err.response.data.error.details })
        break
      case 401:
        Notification.error({ content: '未授权，请登录' })
        break

      case 403:
        Notification.error({ content: '拒绝访问' })
        break

      case 404:
        Notification.error({ content: '请求地址出错' })
        break

      case 408:
        Notification.error({ content: '请求超时' })
        break

      case 500:
        Notification.error({ content: '服务器内部错误' })
        break

      case 501:
        Notification.error({ content: '服务未实现' })
        break

      case 502:
        Notification.error({ content: '网关错误' })
        break

      case 503:
        Notification.error({ content: '服务不可用' })
        break

      case 504:
        Notification.error({ content: '网关超时' })
        break

      case 505:
        Notification.error({ content: 'HTTP版本不受支持' })
        break
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing (url, params, data) {
  if (data.code === -1) {
  }
}
// function findMatchIndex(map, config) {
//   //console.log(map, config, "map, config");
//   return map.findIndex((item) => {
//     return (
//       item.url === config.url &&
//       item.method === config.method &&
//       diff(item.params, config.params ? config.params : config.data)
//     );
//   });
// }

// function diff(obj1, obj2) {
//   if (Object.keys(obj1).length !== Object.keys(obj2).length) {
//     return false;
//   }
//   let obj1Keys = Object.keys(obj1);
//   for (let i = 0; i < obj1Keys.length; i++) {
//     if (obj1[obj1Keys[i]] !== obj2[obj1Keys[i]]) {
//       return false;
//     }
//   }
//   return true;
// }
